<div class="header-container">
    <button
        mat-button
        class="back-button"
        *ngIf="showBack()"
        (click)="goBack()"
    >
        <mat-icon>arrow_back</mat-icon>
        Back
    </button>
    <div class="back-button-placeholder" *ngIf="!showBack()"></div>
    <div class="logo-container">
        <div class="logo-wrapper">
            <a href="https://www.altruist.dev/" target="_blank">
                <img
                    ngSrc="../../../assets/icons/altruist/logo-gray.svg"
                    alt="Altruist Logo"
                    class="logo default-logo"
                    height="45"
                    width="60"
                />

                <img
                    ngSrc="../../../assets/icons/altruist/logo-colored.svg"
                    alt="Altruist Logo Colored"
                    class="logo colored-logo"
                    height="45"
                    width="60"
                />
            </a>
        </div>
    </div>
    <button mat-button class="FAQ-button" (click)="openFAQ()">FAQ</button>
</div>
