import {
    Component,
    DestroyRef,
    inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ResponseService } from 'src/app/services/response/response.service';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MetroArea } from 'src/app/models/metro-area.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    metros: MetroArea[] = [];
    states: string[] = [];
    inputForm: FormGroup;
    filteredStates!: Observable<string[]>;
    filteredMetros!: Observable<string[]>;
    destroyRef = inject(DestroyRef);

    constructor(
        private responseService: ResponseService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.inputForm = new FormGroup({
            state: new FormControl('', [
                Validators.required,
                this.validateOption(() => this.states),
            ]),
            metroArea: new FormControl({ value: '', disabled: true }, [
                Validators.required,
                this.validateMetroArea(() => this.metros),
            ]),
        });
    }

    ngOnInit() {
        this.responseService.getStatesList()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
            next: (states) => {
                this.states = states.map((s: any) => s.state);
            },
            error: (error) => {
                if (error === 'apiFailure') {
                    this.router.navigate(['/results']);
                }
            },
        });

        this.updateBg(
            'linear-gradient(246deg, #0089D9 0%, #00A2FF 43.75%, #51C7C7 86.98%'
        );

        this.filteredStates = this.inputForm.get('state')!.valueChanges.pipe(
            startWith(''),
            map((value) => this.filterMenu(value, this.states))
        );

        this.filteredMetros = this.inputForm.get('metroArea')!.valueChanges.pipe(
            startWith(''),
            map((value) => this.filterMetroArea(value))
        );
    }

    updateBg(gradient: string) {
        document.documentElement.style.setProperty('--bgImage', gradient);
    }

    ngOnDestroy() {
        this.updateBg('white');
    }

    private filterMenu(value: string, menuItems: string[]): string[] {
        if (value === null || value.length < 2) {
            return [];
        }

        const filterValue = value.toLowerCase();
        return menuItems.filter((item) => 
            item.toLowerCase().includes(filterValue)
        );
    }

    private filterMetroArea(value: string): string[] {
        if (value === null || value.length < 2) {
            return [];
        }

        const metroDescriptions = this.metros.map(m => m.description);
        return this.filterMenu(value, metroDescriptions);
    }

    onNextClick(): void {
        if (this.inputForm.valid) {
            var locationId = this.metros.find(m => m.description === this.inputForm.value.metroArea)?.locationId;
            this.inputForm.value.metroArea = locationId;

            this.responseService.updateResponse(this.inputForm.value);
            this.router.navigate(['../1'], {
                relativeTo: this.route,
            });
        } else {
            this.inputForm.markAllAsTouched();
        }
    }

    validateOption(options: () => string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = options().includes(control.value);
            return isValid ? null : { invalidOption: { value: control.value } };
        };
    }

    validateMetroArea(options: () => MetroArea[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = options().map(m => m.description).includes(control.value);
            return isValid ? null : { invalidOption: { value: control.value } };
        };

    }

    resetField(fieldName: string): void {
        this.inputForm.get(fieldName)?.reset();
        if (fieldName === 'state') {
            this.inputForm.get('metroArea')!.reset();
            this.inputForm.get('metroArea')!.disable();
/*             this.filteredStates = this.inputForm
                .get('state')!
                .valueChanges.pipe(
                    startWith(''),
                    map((value) => this.filterMenu(value, this.states))
                );
         } else if (fieldName === 'metroArea') {
            this.filteredMetros = this.inputForm
                .get('metroArea')!
                .valueChanges.pipe(
                    startWith(''),
                    map((value) => this.filterMetroArea(value))
                );*/
        }
    }

    setFieldValue(fieldName: string, value: any): void {
        this.inputForm.get(fieldName)?.setValue(value);
    }

    setStateValue(value: any): void {
        this.setFieldValue('state', value);
        this.responseService.getMetroAreasList(value)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
            next: (metroAreas) => {
                this.metros = metroAreas;
                this.inputForm.get('metroArea')!.enable();
            },
            error: (error) => {
                if (error === 'apiFailure') {
                    this.router.navigate(['/results']);
                }
            },
        });
    }
}
