<div class="email-dialog">
    <button mat-icon-button class="close-button" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
    <h1 class="email-title">
        Please fill out the form below to get your personalized report
    </h1>
    <form [formGroup]="emailForm" #form="ngForm" id="name-form">
        <div class="inputs">
            <mat-form-field class="emailInputs" appearance="outline">
                <mat-label>Enter your first name</mat-label>
                <input
                    matInput
                    formControlName="firstname"
                    [errorStateMatcher]="matcher"
                />
                <mat-error>First name is required</mat-error>
            </mat-form-field>
            <mat-form-field class="emailInputs" appearance="outline">
                <mat-label>Enter your last name</mat-label>
                <input
                    matInput
                    formControlName="lastname"
                    [errorStateMatcher]="matcher"
                />
                <mat-error>Last name is required</mat-error>
            </mat-form-field>
            <mat-form-field class="emailInputs" appearance="outline">
                <mat-label>Enter your email</mat-label>
                <input
                    matInput
                    formControlName="email"
                    [errorStateMatcher]="matcher"
                />
                <mat-error>A valid email is required</mat-error>
            </mat-form-field>
            <mat-form-field class="emailInputs" appearance="outline">
                <mat-label>Enter your organization (optional)</mat-label>
                <input matInput formControlName="organization" />
            </mat-form-field>
        </div>
        <div class="terms">
            <p class="legal">
                By accessing and utilizing our services you hereby acknowledge
                and consent to adhere to our Terms of Service.
            </p>
            <mat-checkbox class="checkbox" formControlName="terms">
                I agree to the Altruist Platform Terms of Service and to receive
                other communications from Altruist *
            </mat-checkbox>
            <mat-error *ngIf="emailForm.controls['terms'].hasError('required')">
                You must agree to the terms to proceed.
            </mat-error>
            <p class="legal">
                You may unsubscribe from these communications at any time.
            </p>
            <p class="legal">
                For more information on how to unsubscribe, our privacy
                practices, and how we are committed to protecting and respecting
                your privacy, please review our
                <a
                    href="https://www.altruist.dev/privacy"
                    target="_blank"
                    class="privacy-link"
                    >Privacy Policy</a
                >.
            </p>
        </div>
    </form>
    <div class="email-button">
        <button mat-icon-button class="send-email-button" (click)="onSubmit()">
            <mat-icon>email</mat-icon>
        </button>
    </div>
</div>
