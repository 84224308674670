import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionFourComponent } from './question-four/question-four.component';
import { QuestionOneComponent } from './question-one/question-one.component';
import { QuestionThreeComponent } from './question-three/question-three.component';
import { QuestionTwoComponent } from './question-two/question-two.component';
import { ResultsComponent } from './results/results.component';
import { SurveyComponent } from './survey.component';
import { HomeComponent } from './home/home.component';
import { LoadingComponent } from '../../components/loading/loading.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: SurveyComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: '1', component: QuestionOneComponent },
            { path: '2', component: QuestionTwoComponent },
            { path: '3', component: QuestionThreeComponent },
            { path: '4', component: QuestionFourComponent },
            { path: 'loading', component: LoadingComponent },
            { path: 'results', component: ResultsComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SurveyRouterModule {}
