import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseService } from '../../../services/response/response.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMatcher } from '../../../components/error-matcher.component';

@Component({
    selector: 'app-question-two',
    templateUrl: './question-two.component.html',
    styleUrls: ['./question-two.component.scss'],
})
export class QuestionTwoComponent {
    inputForm: FormGroup;
    matcher = new ErrorMatcher(); // Add this line

    constructor(
        private fb: FormBuilder,
        private responseService: ResponseService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.inputForm = this.fb.group({
            activeVolunteers: [
                '',
                [Validators.required, Validators.pattern('^\\d*(\\.?\\d+)$')],
            ],
            hoursWorked: [
                '',
                [Validators.required, Validators.pattern('^\\d*(\\.?\\d+)$')],
            ],
        });
    }

    onNextClick(): void {
        if (this.inputForm.valid) {
            this.responseService.updateResponse(this.inputForm.value);
            this.router.navigate(['../3'], {
                relativeTo: this.route,
            });
        } else {
            this.inputForm.markAllAsTouched();
        }
    }
}
