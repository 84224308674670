<div class="survey-page">
    <app-header class="header"></app-header>
    <div class="question-content">
        <router-outlet></router-outlet>
    </div>
    <app-copyright
        class="survey-copyright"
        [color]="copyrightColor"
    ></app-copyright>
</div>
