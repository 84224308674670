<div class="faq-dialog">
    <div class="faq-header">
        <h1 class="faq-title">
            Have any questions about Volunteer ROI Calculator?
        </h1>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-accordion class="faq-accordion">
        <mat-expansion-panel class="faq-panel" *ngFor="let faq of faqs">
            <mat-expansion-panel-header>
                <mat-panel-title class="faq-panel-header">
                    {{ faq.question }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="faq-panel-content" [innerHTML]="faq.answer"></div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
