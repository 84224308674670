<div class="question-one-page">
    <h1 class="title">Help us understand the work your volunteers do</h1>

    <div class="job-item">
        <form [formGroup]="inputForm">
            <div formArrayName="jobs">
                <div
                    class="inputs-container"
                    *ngFor="let job of jobs.controls; let i = index"
                    [formGroupName]="i"
                >
                    <div class="search-bar-2">
                        <mat-form-field
                            class="input-field"
                            appearance="outline"
                        >
                            <mat-icon matPrefix>accessibility_new</mat-icon>
                            <input
                                matInput
                                formControlName="jobId"
                                placeholder="Type of Work"
                                [matMenuTriggerFor]="jobTypeMenu"
                            />
                            <button
                                mat-icon-button
                                matSuffix
                                *ngIf="job.get('jobId')?.value"
                                (click)="resetField(i, 'jobId')"
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-icon
                                matSuffix
                                *ngIf="!job.get('jobId')?.value"
                                >search
                            </mat-icon>
                        </mat-form-field>

                        <mat-menu class="search-menu" #jobTypeMenu="matMenu">
                            <button
                                mat-menu-item
                                *ngFor="
                                    let jobType of filteredJobsList[i]
                                        | async
                                        | slice : 0 : 8
                                "
                                (click)="setFieldValue(i, 'jobId', jobType)"
                            >
                                {{ jobType }}
                            </button>
                        </mat-menu>
                    </div>
                    <div class="slider">
                        <mat-slider
                            min="0"
                            max="100"
                            step="1"
                            discrete
                            [displayWith]="formatLabel"
                        >
                            <input
                                matSliderThumb
                                formControlName="percentHoursVolunteerWorked"
                            />
                        </mat-slider>
                        <p class="helper-text">
                            Slide the bubble to enter the total volunteer work
                            of this type 0% - 100%
                        </p>
                    </div>
                    <button mat-icon-button (click)="removeJobType(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </form>

        <!-- Add new job type button -->
        <div class="add-job">
            <button mat-icon-button (click)="addJobType()">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </div>

    <div class="next-container">
        <app-progress></app-progress>
        <div *ngIf="totalSliderError" class="error-message">
            Sum of all slider values together must equal 100%
        </div>
        <app-next-button (onClickEvent)="onNextClick()"></app-next-button>
    </div>
</div>
