<div class="page-layout">
    <div class="top-component">
        <h1 class="title">
            Let’s learn a bit more about the costs associated with your
            volunteer operations
        </h1>
    </div>

    <div class="content">
        <form [formGroup]="inputForm">
            <div class="question">
                <h4 class="question">
                    1. Number of paid staff hours required to manage volunteers
                </h4>
                <mat-form-field class="question-inputs" appearance="outline">
                    <mat-label>Estimate on a monthly basis</mat-label>
                    <input
                        matInput
                        formControlName="managerHours"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error
                        *ngIf="
                            inputForm.get('managerHours')?.hasError('required')
                        "
                    >
                        This field is required.
                    </mat-error>
                    <mat-error
                        *ngIf="
                            inputForm.get('managerHours')?.hasError('pattern')
                        "
                    >
                        Please enter a valid number.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <div class="question">
                <h4 class="question">
                    2. Estimated amount spent on volunteer supplies and
                    equipment
                </h4>
                <mat-form-field class="question-inputs" appearance="outline">
                    <mat-label>Estimate on a monthly basis</mat-label>
                    <input
                        matInput
                        formControlName="supplyCosts"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error
                        *ngIf="inputForm.get('supplyCosts')?.hasError('required')"
                    >
                        This field is required.
                    </mat-error>
                    <mat-error
                        *ngIf="inputForm.get('supplyCosts')?.hasError('pattern')"
                    >
                        Please enter a valid number.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <div class="question">
                <h4 class="question">
                    3. Other amounts spent on volunteer program expenses
                </h4>
                <mat-form-field class="question-inputs" appearance="outline">
                    <mat-label>Estimate on a monthly basis</mat-label>
                    <input
                        matInput
                        formControlName="otherCosts"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error
                        *ngIf="
                            inputForm.get('otherCosts')?.hasError('required')
                        "
                    >
                        This field is required.
                    </mat-error>
                    <mat-error
                        *ngIf="inputForm.get('otherCosts')?.hasError('pattern')"
                    >
                        Please enter a valid number.
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="next-container">
        <app-progress></app-progress>
        <app-next-button (onClickEvent)="onNextClick()"></app-next-button>
    </div>
</div>
