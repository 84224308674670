<div class="page-layout">
    <div class="top-component">
        <h1 class="title">
            Apart from dedicating their time, how do volunteers contribute to
            your organization's mission?
        </h1>
    </div>

    <div class="content">
        <form [formGroup]="inputForm">
            <div class="question">
                <h4 class="question">
                    1. Amount of monetary donations made by an average volunteer
                </h4>
                <mat-form-field class="question-inputs" appearance="outline">
                    <mat-label>Estimate on a monthly basis</mat-label>
                    <input
                        matInput
                        formControlName="monetaryDonations"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error
                        *ngIf="
                            inputForm
                                .get('monetaryDonations')
                                ?.hasError('required')
                        "
                    >
                        This field is required.
                    </mat-error>
                    <mat-error
                        *ngIf="
                            inputForm
                                .get('monetaryDonations')
                                ?.hasError('pattern')
                        "
                    >
                        Please enter a valid number.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <div class="question">
                <h4 class="question">
                    2. Estimate average value of goods donated by an average
                    volunteer
                </h4>
                <mat-form-field class="question-inputs" appearance="outline">
                    <mat-label>Estimate on a monthly basis</mat-label>
                    <input
                        matInput
                        formControlName="inKindDonations"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error
                        *ngIf="
                            inputForm.get('inKindDonations')?.hasError('required')
                        "
                    >
                        This field is required.
                    </mat-error>
                    <mat-error
                        *ngIf="
                            inputForm.get('inKindDonations')?.hasError('pattern')
                        "
                    >
                        Please enter a valid number.
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="next-container">
        <app-progress></app-progress>
        <app-next-button (onClickEvent)="onNextClick()"></app-next-button>
    </div>
</div>
