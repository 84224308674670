import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent {
    constructor(private router: Router, private route: ActivatedRoute) {}

    get copyrightColor(): string {
        return this.router.url === '/home' ? 'white' : 'black';
    }
}
