import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FaqService } from '../../services/faq/faq.service';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent {
    faqs = this.faqService.faqs;

    constructor(
        public dialogRef: MatDialogRef<FaqPageComponent>,
        private faqService: FaqService
    ) {}

    closeDialog() {
        this.dialogRef.close();
    }
}
