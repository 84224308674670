import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyComponent } from './survey.component';
import { HomeComponent } from './home/home.component';
import { QuestionOneComponent } from './question-one/question-one.component';
import { QuestionTwoComponent } from './question-two/question-two.component';
import { QuestionThreeComponent } from './question-three/question-three.component';
import { QuestionFourComponent } from './question-four/question-four.component';
import { ResultsComponent } from './results/results.component';
import { SurveyRouterModule } from './survey-router.module';
import { SharedModule } from '../../components/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    declarations: [
        SurveyComponent,
        HomeComponent,
        QuestionOneComponent,
        QuestionTwoComponent,
        QuestionThreeComponent,
        QuestionFourComponent,
        ResultsComponent,
    ],
    imports: [
        CommonModule,
        SurveyRouterModule,
        SharedModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatDividerModule,
        MatInputModule,
        MatDialogModule,
        FormsModule,
        MatIconModule,
        MatMenuModule,
        MatSliderModule,
    ],
})
export class SurveyModule {}
