// Angular imports
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';

// Components
import { CopyrightComponent } from './copyright/copyright.component';
import { FaqButtonComponent } from './faq-button/faq-button.component';
import { HeaderComponent } from './header/header.component';
import { NextButtonComponent } from './next-button/next-button.component';
import { ProgressComponent } from './progress/progress.component';
import { LoadingComponent } from './loading/loading.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [
        HeaderComponent,
        LoadingComponent,
        FaqButtonComponent,
        FaqPageComponent,
        CopyrightComponent,
        NextButtonComponent,
        ProgressComponent,
        EmailDialogComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatExpansionModule,
        MatRippleModule,
        MatMenuModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
    ],
    exports: [
        HeaderComponent,
        FaqButtonComponent,
        CopyrightComponent,
        NextButtonComponent,
        ProgressComponent,
    ],
})
export class SharedModule {}
