import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ResponseObject } from '../../models/response-obj.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ResponseService {
    response = new BehaviorSubject<ResponseObject>({} as ResponseObject);
    private apiBaseURI = environment.apiUrl;

    constructor(private http: HttpClient) {}

    //calculate ROI from API
    getRoiResults(): Observable<any> {
        const uri = `${this.apiBaseURI}/api/roi-calculator/calculate`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const data = {
            locationId: this.response.value.metroArea,
            jobVolunteerHours: this.response.value.jobs,
            volunteerCount: this.response.value.activeVolunteers,
            volunteerHoursWorked: this.response.value.hoursWorked,
            volunteerMonetaryDonation: this.response.value.monetaryDonations,
            volunteerInKindDonation: this.response.value.inKindDonations,
            equipmentSupplyCost: this.response.value.supplyCosts,
            otherExpenseCost: this.response.value.otherCosts
        };

        return this.http.post<any>(uri, data, { headers: headers }).pipe(
            catchError((err) => {
                return throwError(() => err.status);
            })
        );
    }

    //send email from API
    sendEmail(avgValueVolunteerHour: number, avgVolunteerContribution: number, avgVolunteerOperationsCost: number, volunteerProgramROI: number): Observable<any> {
        const uri = `${this.apiBaseURI}/api/roi-calculator/email`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const data = {
            to: this.response.value.email,
            averageValueVolunteerHour: avgValueVolunteerHour,
            averageVolunteerContribution: avgVolunteerContribution,
            averageVolunteerOperationsCost: avgVolunteerOperationsCost,
            volunteerProgramROI: volunteerProgramROI
        };

        return this.http.post<any>(uri, data, { headers: headers }).pipe(
            catchError((err) => {
                return throwError(() => err.status);
            })
        );
    }

    saveContact(): Observable<any> {
        const uri = `${this.apiBaseURI}/api/roi-calculator/contact`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const data = {
            email: this.response.value.email,
            firstName: this.response.value.firstName,
            lastName: this.response.value.lastName,
            organizationName: this.response.value.orgName
        };

        return this.http.post<any>(uri, data, { headers: headers }).pipe(
            catchError((err) => {
                return throwError(() => err.status);
            })
        );
    }

    //retrieve list of geographical states from API
    getStatesList(): Observable<any> {
        const uri = `${this.apiBaseURI}/api/roi-calculator/states`;

        return this.http.get<any>(uri).pipe(
            catchError(err => {
                return throwError(() =>new Error(err));
            })
        )
    }

    //retrieve locations from the API
    getMetroAreasList(state: string): Observable<any> {
        const uri = `${this.apiBaseURI}/api/roi-calculator/locations?state=${state}`;

        return this.http.get<any>(uri).pipe(
            catchError(err => {
                return throwError(() =>new Error(err));
            })
        )
    }

    //retrieve jobs from the API
    getVolunteerTypes(): Observable<any> {
        const uri = `${this.apiBaseURI}/api/roi-calculator/jobs`;

        return this.http.get<any>(uri).pipe(
            catchError(err => {
                return throwError(() =>new Error(err));
            })
        )
    }

    updateResponse(input: Partial<ResponseObject>): void {
        const currInput = this.response.getValue();
        const updated = { ...currInput, ...input };
        this.response.next(updated);
    }
}
