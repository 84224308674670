import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {
    questionNumber: number = 0;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        const urlSplit = this.route.snapshot.url.map((segment) => segment.path);
        const pageNumber = urlSplit[urlSplit.length - 1];
        this.questionNumber = +pageNumber;
    }
}
