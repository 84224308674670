<div class="page-layout">
    <div class="top-component">
        <h1 class="title">
            Let’s learn more about your typical volunteer workforce
        </h1>
    </div>

    <div class="content">
        <form [formGroup]="inputForm">
            <div class="question">
                <h4 class="question">1. Average number of active volunteers</h4>
                <mat-form-field class="question-inputs" appearance="outline">
                    <mat-label>Estimate on a monthly basis</mat-label>
                    <input
                        matInput
                        formControlName="activeVolunteers"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error
                        *ngIf="
                            inputForm
                                .get('activeVolunteers')
                                ?.hasError('required')
                        "
                    >
                        This field is required.
                    </mat-error>
                    <mat-error
                        *ngIf="
                            inputForm
                                .get('activeVolunteers')
                                ?.hasError('pattern')
                        "
                    >
                        Please enter a valid number.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <div class="question">
                <h4 class="question">
                    2. Average number of hours worked by each volunteer
                </h4>
                <mat-form-field class="question-inputs" appearance="outline">
                    <mat-label>Estimate on a monthly basis</mat-label>
                    <input
                        matInput
                        formControlName="hoursWorked"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error
                        *ngIf="
                            inputForm.get('hoursWorked')?.hasError('required')
                        "
                    >
                        This field is required.
                    </mat-error>
                    <mat-error
                        *ngIf="
                            inputForm.get('hoursWorked')?.hasError('pattern')
                        "
                    >
                        Please enter a valid number.
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="next-container">
        <app-progress></app-progress>
        <app-next-button (onClickEvent)="onNextClick()"></app-next-button>
    </div>
</div>
