<div class="copyright" [style.color]="color">
    <div>Copyright © 2023 Altruist ROI Calculator</div>
    <div>
        <a
            class="link-tag-styles"
            href="https://www.altruist.dev/privacy"
            target="_blank"
            >Privacy</a
        >
        //
        <a
            class="link-tag-styles"
            href="https://www.altruist.dev/terms"
            target="_blank"
            >Legal</a
        >
        // Cookies
    </div>
</div>
