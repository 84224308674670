import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResponseService } from '../../services/response/response.service';
import {
    FormBuilder,
    FormGroup,
    Validators,
    AbstractControl,
} from '@angular/forms';
import { ErrorMatcher } from '../error-matcher.component';

@Component({
    selector: 'app-email-dialog',
    templateUrl: './email-dialog.component.html',
    styleUrls: ['./email-dialog.component.scss'],
})
export class EmailDialogComponent {
    emailForm: FormGroup;
    matcher = new ErrorMatcher();

    constructor(
        public dialogRef: MatDialogRef<EmailDialogComponent>,
        public responseSvcs: ResponseService,
        private fb: FormBuilder
    ) {
        this.emailForm = this.fb.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.required, this.emailValidator]],
            organization: [''],
            terms: [false, Validators.requiredTrue],
        });
    }

    onSubmit(): void {
        if (this.emailForm.valid) {
            this.responseSvcs.updateResponse({
                firstName: this.emailForm.value.firstname,
                lastName: this.emailForm.value.lastname,
                email: this.emailForm.value.email,
                orgName: this.emailForm.value.organization,
            });
 
            this.dialogRef.close();
        } else {
            this.emailForm.markAllAsTouched();
        }
    }

    onClose(): void {
        this.dialogRef.close();
    }

    emailValidator(control: AbstractControl): { [key: string]: any } | null {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const valid = emailRegex.test(control.value);
        return valid ? null : { invalidEmail: { value: control.value } };
    }
}
