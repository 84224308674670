import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { EmailDialogComponent } from '../../../components/email-dialog/email-dialog.component';
import { Router } from '@angular/router';
import { ResponseService } from 'src/app/services/response/response.service';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnDestroy {
    hideReport = true;
    isError = false;
    errorType: string = 'apiFailure';
    destroyRef = inject(DestroyRef);

    //initialized as string types for use in the UI
    avgValueVolunteerHour = 0.00;
    avgVolunteerContribution = 0.00;
    avgVolunteerOperationsCost = 0.00;
    volunteerProgramROI = 0.00;

    constructor(
        private overlay: MatDialog,
        private router: Router,
        private responseService: ResponseService
    ) {}

    ngOnInit(): void {
        this.generateReport();
    }

    generateReport(): void {
        const dialogRef = this.overlay.open(LoadingComponent, {
            width: 'calc(100vw - 32px)',
            maxWidth: '100vw',
            height: 'calc(100vh - 32px)',
            maxHeight: '95vh',
            disableClose: true,
        });

        this.responseService.getRoiResults()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
            next: (res) => {
                this.avgValueVolunteerHour = res.averageValueVolunteerHour;
                this.avgVolunteerContribution = res.averageVolunteerContribution;
                this.avgVolunteerOperationsCost = res.averageVolunteerOperationsCost;
                this.volunteerProgramROI = res.volunteerProgramROI;
                dialogRef.close();
            },
            error: () => {
                this.isError = true;
                this.errorType = 'apiFailure';
                dialogRef.close();
            },
        });
    }

    onEmailClick(): void {
        const emailDialogRef = this.overlay.open(EmailDialogComponent, {
            width: 'calc(100vw - 32px)',
            maxWidth: '100vw',
            height: 'calc(100vh - 32px)',
            maxHeight: '95vh',
            disableClose: true,
        });

        emailDialogRef.afterClosed().subscribe(() => {
            forkJoin([
                this.responseService.sendEmail(this.avgValueVolunteerHour, this.avgVolunteerContribution, this.avgVolunteerOperationsCost, this.volunteerProgramROI),
                this.responseService.saveContact()
            ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.hideReport = false;
                },
                error: () => {
                    this.isError = true;
                    this.errorType = 'emailFailure';
                },
            });
        });
        /*
        this.responseService
                .sendEmail(this.avgValueVolunteerHour, this.avgVolunteerContribution, this.avgVolunteerOperationsCost, this.volunteerProgramROI)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: () => {
                        this.hideReport = false;
                    },
                    error: () => {
                        this.isError = true;
                        this.errorType = 'emailFailure';
                    },
                });
            this.responseService
                .saveContact()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe();
        });
        */
    }

    onStartOverClick(): void {
        this.router.navigate(['/home']);
    }

    ngOnDestroy(): void {}
}
