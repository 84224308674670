import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FaqService {
    faqList = [
        {
            question:
                'What is the Volunteer ROI Calculator and how will it impact me?',
            answer:
                'The Volunteer ROI (Return on Investment) Calculator is a tool ' +
                'designed to help organizations and nonprofits assess the value they ' +
                'receive from their volunteer programs. It helps quantify the impact ' +
                'and benefits of volunteer engagement in financial terms, allowing ' +
                'organizations to understand the return on their investment of time, ' +
                'effort, and resources into managing and supporting volunteers.',
        },
        {
            question: 'How does it work?',
            answer:
                'We just need to collect a little information about what your ' +
                'volunteers do, any additional details about other contributions ' +
                'they provide for your organization, and what costs are associated ' +
                'with running the volunteer program. From there, we can calculate your ROI! ',
        },
        {
            question:
                'What if I don’t know all the answers to these questions?',
            answer:
                'There are certain answers we absolutely need to make this ' +
                'calculation, but there are a few we can go without. Although we ' +
                'encourage you to answer as many optional questions as possible ' +
                'for the most accurate ROI calculation, you do not have to enter ' +
                'an answer for the questions asking about additional contributions ' +
                'or additional costs. ',
        },
        {
            question: 'Do you store my data?',
            answer:
                'Volunteer ROI Calculator does not store any personally ' +
                'identifiable data. Information will only be collected for ' +
                'marketing purposes if you opt-in to marketing emails. If you ' +
                'have agreed to us using your personal information for direct ' +
                'marketing purposes, you may change your mind by unsubscribing ' +
                'within the email. ',
        },
        {
            question: 'What other volunteering products do you have?',
            answer:
                'Thanks for asking! ' +
                '<br><br> We do have another product called ' +
                '<a class="link-tag-styles" href="https://www.altruist.dev/" target="_blank">Altruist</a>' +
                ', a simple and modern volunteer management platform.',
        },
    ];

    constructor() {}

    get faqs() {
        return [...this.faqList]; // Return a copy to ensure immutability
    }
}
