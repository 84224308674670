import {
    Component,
    DestroyRef,
    inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { ResponseService } from '../../../services/response/response.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JobType } from 'src/app/models/job-type.model';

@Component({
    selector: 'app-question-one',
    templateUrl: './question-one.component.html',
    styleUrls: ['./question-one.component.scss'],
})
export class QuestionOneComponent implements OnInit, OnDestroy {
    inputForm: FormGroup;
    filteredJobsList: Observable<string[]>[] = [];
    jobTypes: JobType[] = []; // Initialize as empty array
    totalSliderError: boolean = false;
    destroyRef = inject(DestroyRef);

    constructor(
        private fb: FormBuilder,
        private responseService: ResponseService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.inputForm = this.fb.group({
            jobs: this.fb.array([this.createJobType()]),
        });
    }

    ngOnInit() {
        this.responseService.getVolunteerTypes()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
            next: (jobTypes) => {
                this.jobTypes = jobTypes;
                this.jobs.controls.forEach((control, index) => {
                    this.initFilterControl(control as FormGroup, index);
                });
            },
            error: (error) => {
                if (error === 'apiFailure') {
                    this.router.navigate(['/results']);
                }
            },
        });
    }

    ngOnDestroy() {}

    initFilterControl(control: FormGroup, index: number): void {
        this.filteredJobsList[index] = control
            .get('jobId')!
            .valueChanges.pipe(
                startWith(''),
                map((value) => this.filterMenu(value, this.jobTypes.map((jt: any) => jt.description)))
            );
    }

    private filterMenu(value: string, menuItems: string[]): string[] {
        if (value === null || value.length < 2) {
            return [];
        }

        const filterValue = value.toLowerCase();
        return menuItems.filter((item) =>
            item.toLowerCase().includes(filterValue)
        );
    }

    resetField(index: number, fieldName: string): void {
        const group = this.jobs.at(index) as FormGroup;
        group.get(fieldName)?.reset();
        this.initFilterControl(group, index);
    }

    setFieldValue(index: number, fieldName: string, value: any): void {
        const group = this.jobs.at(index) as FormGroup;
        group.get(fieldName)?.setValue(value);
    }

    get jobs(): FormArray {
        return this.inputForm.get('jobs') as FormArray;
    }

    removeJobType(index: number): void {
        this.jobs.removeAt(index);
    }

    createJobType(): FormGroup {
        return this.fb.group({
            jobId: [
                '',
                [Validators.required, this.jobTypeValidator(() => this.jobTypes.map((jt: any) => jt.description))],
            ],
            percentHoursVolunteerWorked: [0, Validators.required],
        });
    }

    jobTypeValidator(jobTypes: () => string[]): Validators {
        return (control: FormControl): { [key: string]: any } | null => {
            const value = control.value;
            if (value && jobTypes().includes(value)) {
                return null; // valid
            }
            return { invalidJobType: { value: control.value } }; // invalid
        };
    }

    addJobType(): void {
        const control = this.createJobType();
        this.jobs.push(control);
        this.initFilterControl(control, this.jobs.length - 1);
    }

    formatLabel(value: number): string {
        return `${value}`;
    }

    onNextClick(): void {
        const totalSliderValue = this.jobs.controls.reduce((sum, control) => {
            let group = control as FormGroup;
            let controlValue = group.get('percentHoursVolunteerWorked');
            return sum + (controlValue ? controlValue.value : 0);
        }, 0);

        if (totalSliderValue !== 100) {
            this.totalSliderError = true;
            return;
        }

        if (this.inputForm.valid && totalSliderValue === 100) {
            for (let i = 0; i < this.jobs.length; i++) {
                var job = this.jobs.at(i) as FormGroup;
                var description = job.controls['jobId'].value;
                var jobId = this.jobTypes.find(jt => jt.description === description)?.jobId;
                job.controls['jobId'].setValue(jobId);
            }

            this.responseService.updateResponse(this.inputForm.value);
            this.router.navigate(['../2'], {
                relativeTo: this.route,
            });
        } else {
            this.inputForm.markAllAsTouched();
        }
    }
}
