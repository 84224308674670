<div class="home-page">
    <div class="top-content">
        <h1 class="title">
            Welcome to the Volunteer Value Calculator, powered by Altruist
        </h1>
        <div class="text-container">
            <div class="text-box">
                <h5 class="body">
                    This calculator is built to help volunteer management teams
                    quantify the value of their volunteering operation.
                </h5>
            </div>
            <div class="text-box">
                <h5 class="body">
                    Understanding the value your volunteers provide to your
                    organization can help you earn matching grants, improve
                    volunteer coordination resource allocation, and improve
                    volunteer recruiting!
                </h5>
            </div>
        </div>
    </div>
    <form [formGroup]="inputForm">
        <div class="inputs-container">
            <div class="search-bar">
                <mat-form-field class="search-text" appearance="fill">
                    <mat-icon matPrefix>my_location</mat-icon>
                    <input
                        matInput
                        formControlName="state"
                        placeholder="Select a state"
                        [matMenuTriggerFor]="stateMenu"
                    />
                    <button
                        mat-icon-button
                        matSuffix
                        *ngIf="inputForm.get('state')?.value"
                        (click)="resetField('state')"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-icon matSuffix *ngIf="!inputForm.get('state')?.value"
                        >search
                    </mat-icon>
                </mat-form-field>

                <mat-menu class="search-menu" #stateMenu="matMenu">
                    <button
                        mat-menu-item
                        *ngFor="
                            let state of filteredStates | async | slice : 0 : 5
                        "
                        (click)="setStateValue(state)"
                    >
                        {{ state }}
                    </button>
                </mat-menu>
            </div>

            <div class="reuse-search">
                <mat-form-field class="input-field" appearance="outline">
                    <mat-icon matPrefix>location_on</mat-icon>
                    <input
                        matInput
                        formControlName="metroArea"
                        placeholder="Metro Area"
                        [matMenuTriggerFor]="metroMenu"
                    />
                    <button
                        mat-icon-button
                        matSuffix
                        *ngIf="inputForm.get('metroArea')?.value"
                        (click)="resetField('metroArea')"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-icon
                        matSuffix
                        *ngIf="!inputForm.get('metroArea')?.value"
                        >search
                    </mat-icon>
                </mat-form-field>

                <mat-menu class="search-menu" #metroMenu="matMenu">
                    <button
                        mat-menu-item
                        *ngFor="
                            let metro of filteredMetros | async | slice : 0 : 10
                        "
                        (click)="setFieldValue('metroArea', metro)"
                    >
                        {{ metro }}
                    </button>
                </mat-menu>
            </div>
        </div>
    </form>
    <div class="next-container">
        <app-next-button (onClickEvent)="onNextClick()"></app-next-button>
    </div>
</div>
