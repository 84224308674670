<div class="page-layout">
    <div class="page-top">
        <h1 class="title" *ngIf="!isError">
            Here is your personalized report!
        </h1>
        <h1
            class="title"
            *ngIf="
                isError &&
                (errorType === 'apiFailure' || errorType === 'emailFailure')
            "
        >
            It looks like this isn’t working right now
        </h1>
        <div class="roi-main">
            <h1 class="value" *ngIf="!isError">
                {{ avgValueVolunteerHour | currency: 'USD': '$' }}
            </h1>
            <p class="description" *ngIf="!isError">
                Average value of a Volunteer Hour
            </p>
            <p
                class="description"
                *ngIf="
                    isError &&
                    (errorType === 'apiFailure' || errorType === 'emailFailure')
                "
            >
                Please try again soon. Thank you for your Patience.
            </p>
        </div>
    </div>
    <!-- TODO: add data to report-->
    <div class="report" *ngIf="!isError">
        <h2 class="sub-title">
            Please enter your email below to receive the rest of your personalized
            report
        </h2>
        <div class="content">
            <p class="description">
                Average other value contributed by each volunteer:
            </p>
            <img
                *ngIf="hideReport"
                src="../../../../assets/blurred-value.png"
                alt="Blurred val"
            />
            <h3 class="sub-value" *ngIf="!hideReport">
                {{ avgVolunteerContribution | currency: 'USD': '$' }}
            </h3>
        </div>
        <div class="content">
            <p class="description">
                Average cost of supporting volunteer operations:
            </p>
            <img
                *ngIf="hideReport"
                src="../../../../assets/blurred-value.png"
                alt="Blurred val"
            />
            <h3 class="sub-value" *ngIf="!hideReport">
                {{ avgVolunteerOperationsCost | currency: 'USD': '$' }}
            </h3>
        </div>
        <div class="content">
            <p class="description">
                Return on investment of the volunteer program at large:
            </p>
            <img
                *ngIf="hideReport"
                src="../../../../assets/blurred-value.png"
                alt="Blurred val"
            />
            <h3 class="sub-value" *ngIf="!hideReport">
                {{ volunteerProgramROI}}%
            </h3>
        </div>
    </div>

    <div class="button-group">
        <button
            mat-stroked-button
            class="start-over"
            (click)="onStartOverClick()"
        >
            Start Over
        </button>
        <button
            mat-raised-button
            class="email"
            (click)="onEmailClick()"
            *ngIf="!isError"
        >
            Email this to me
        </button>
    </div>

    <div class="page-bottom">
        <h3 class="sub-title">
            Interested in hosting this volunteering event, check out the best
            volunteering management tool out there!
        </h3>
        <div class="button-wrap">
            <a href="https://www.altruist.dev/" target="_blank">
                <button mat-raised-button class="altruist">
                    <img
                        src="../../../../assets/icons/altruist/logo-colored.svg"
                        alt="Altruist Logo Colored"
                        class="logo-icon"
                    />
                    Try Altruist
                </button>
            </a>
        </div>
    </div>
</div>
